:root{
  --block-space: 72px;
}

.App {
  text-align: center;
  background-image: url('/public/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.InnerApp {
  margin: auto;
  min-height: 110vh;
}

@media screen and (min-width: 768px) {
  .InnerApp { max-width: 96vw; }
}

@media screen and (min-width: 1200px) {
  .InnerApp { max-width: 96vw; }
}

@media screen and (min-width: 1840px) {
  .InnerApp { max-width: 1840px; }
}

.App-Font-1 {
  background-image: url('/public/white.png');
  height: 100vh;
  min-height: 914px;
  width: 100%;
  padding-left: 10%;
  padding-top: 20%;
  box-sizing: border-box;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
}

.About-us-box {
  width: 100%;
  margin: var(--block-space) auto;
  padding: 48px 0;
  background-color: rgba(157, 129, 129, 0.3);
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.About-us-title {
  flex: 0.5;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/public/about-us.png');
}

.Video-showroom {
  width: 100%;
  margin: var(--block-space) auto;
}

.About-us-content {
  max-width: 100%;
  padding: 48px;
}

.About-us-logo {
  width: 6rem;
  height: 3rem;
  background-image: url('/public/logo-white.png');
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  margin-bottom: -10px;
}

.Partner-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 128px;
}

.Partner-item img {
  object-fit: contain;
}

.Highlight-typo {
  background-image: url('/public/high-light.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.OurWorkHeader{
  position: relative;
  padding-top: 128px;
  width: 90%;
  margin: 0 auto var(--block-space) auto;
}

.OurWorkTitle {
  color: white;
}

.nav-logo {
  background-image: url('/public/logo.png');
  height: 64px;
  width: 128px;
  background-position: center;
  background-size: cover;
}

.nav-logo:hover {
  cursor: pointer;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  padding: 32px;
  z-index: 10;
}

.App-link {
  color: #61dafb;
}

.VideoListItem:hover {
  cursor: pointer;
}

.VideoListItem:hover .MuiImageListItemBar-title {
  text-decoration: underline;
}

.MuiImageListItemBar-title:hover {
  text-decoration: underline;
}

.CM-Dialog .MuiPaper-root {
  background-color: unset;
  justify-content: center;
}

.OnBoarding {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}